    <template>
  <div class="w-full">
      <div class="md:flex w-full" v-if="mydata.machinInfoMessage == '404'" >
        این ماشین حذف شده است
      {{ newVMNumber }}
        </div>
      <div class="md:flex w-full" v-else >
        <div class="flex-initial w-full md:w-3/12 md:my-0 my-2 mx-2 p-5 rounded bg-primary text-right leading-loose" >
            <span class="block text-left text-base mb-3">
                اطلاعات کلی
            </span>
            <vs-divider />
            <span class="block text-white text-right">
            OS:
                {{ mydata.machine.template.title }}
            </span>
            <span class="block text-white text-right">
            OS Version:
                {{ mydata.machine.template_hw_requirement.version }}
            </span>
            <span class="block text-white text-right">
                Cost/H:
                {{ mydata.machine.vm_cost_per_hour | money}}
            </span>
            <span class="block text-white text-right">
                Cost/M:
                {{ mydata.machine.vm_cost_per_month | money }}
            </span>

            <span class="block text-white">
            CPU: {{ mydata.machine.vm_vcpu }}c
            </span>
           <span class="block text-white">
            RAM: {{ mydata.machine.vm_ram }}GB
            </span>
           <span class="block text-white">
            Hard: {{ mydata.machine.vm_hard }}GB
            </span>
            <vs-divider />
            <span class="block">
                Last Backup:
                {{ mydata.machine.last_backup_at }}
            </span>

            <span class="block ">
                Created Time:
                {{ mydata.machine.created_at }}
                <br />
                Update Time:
                {{ mydata.machine.updated_at }}
            </span>
            <span class="block text-white text-right" v-if="mydata.machine.is_abuse !== 0">
                Abuse:
                {{ mydata.machine.is_abuse }}
                <br />
                {{ mydata.machine.abuse_at }}
                <br />
                {{ mydata.machine.abuse_reason }}
            </span>

        </div>


        <div class="flex-initial w-full md:w-3/12 md:my-0 my-2 mx-2 p-5 rounded bg-primary text-right leading-loose">
            <span class="block text-left text-base mb-3">
           اطلاعات کاربر
            <vs-divider />
            </span>
            <span class="block text-white">
                User ID:
                {{ mydata.machine.user_info.id }}
            </span>
            <span class="block text-white" style="direction:ltr">
                Name:
                {{ mydata.machine.user_info.name }}
            </span>
            <span class="block text-white">
                Email:
                {{ mydata.machine.user_info.email }}
            </span>
            <span class="block text-white">
                Mobile:
                {{ mydata.machine.user_info.mobile }}
            </span>
            <span class="block text-white">
                Credit:
                {{ mydata.machine.user_info.balance | money }}
            </span>
            <span class="block text-white">
                Point:
                {{ mydata.machine.user_info.point_balance | money }}
            </span>


        </div>


        <div class="flex-initial text-right w-full md:w-3/12 md:my-0 my-2 mx-2 p-5 rounded bg-primary text-lg leading-loose">
            <span class="block text-left text-base mb-3">
            <span class="flex justify-between items-center  text-right">
                <span class=" ">
                <span v-if="mydata.machine.vm_status === 4" class="bg-success p-2 rounded-full w-3 h-3 inline-block"></span>
                <span v-else class="bg-warning p-2 rounded-full w-3 h-3 inline-block"></span>
                {{ mydata.machine.vm_status_title }}
            </span>
                <span class=" ">
                {{ mydata.machine.vm_sequence_id }}
                </span>
            </span>
            </span>
            <vs-divider />
            <span class="block text-white">
                {{ mydata.machine.location.country_name_latin }}
            </span>
            <span class="block text-white">
                Node:
                {{ mydata.machine.node_id }}
            </span>

            <span class="block text-white">
                {{ mydata.machine.mac_address }}
            </span>
            <span class="block text-white">
                {{ mydata.machine.vm_master_ip }}
            </span>
            <span class="block text-white text-right">
                Machine ID:
                {{ mydata.machine.id }}
            </span>
        <span class="block text-white text-right">
                Machine Name:
                {{ mydata.machine.vm_title }}
            </span>

        <span class="block text-white text-right">
                {{ mydata.machine.vm_default_username }}
            </span>
        <span class="block text-white text-right">
                {{ mydata.machine.vm_default_password }}
            </span>
            <span v-if="mydata.machine.networks[0].detail.ports[0]" class="block text-white text-right">
                <b-button class="bg-success border-0 p-1">سرور حراجی</b-button><br>
                SSH: {{ mydata.machine.networks[0].detail.ports[0].SSH }}<br>
                RDP: {{ mydata.machine.networks[0].detail.ports[1].RDP }}<br>
                Port_Range: {{ mydata.machine.networks[0].detail.ports[2].Port_Range }}
            </span>
        </div>
        <div class="flex-initial text-right w-full md:w-3/12 md:my-0 my-2 mx-2 p-5 rounded bg-primary text-lg leading-loose ">
            <div class="flex gap-2 mb-8">
              <vs-button @click="confirmAction = true, vm_sequence_id = mydata.machine.vm_sequence_id, type = 'reboot' " class="px-5 py-2 w-full bg-success text-white rounded">ریبوت</vs-button>
              <vs-button  @click="confirmAction = true, vm_sequence_id = mydata.machine.vm_sequence_id, type = 'shutdown'" class="px-5 py-2 w-full bg-warning text-white rounded">خاموش</vs-button >
              <vs-button @click="confirmAction = true, vm_sequence_id = mydata.machine.vm_sequence_id, type = 'delete'" class="px-5 py-2 w-full bg-danger text-white rounded">حذف</vs-button>
            </div>
            <vs-button @click="confirmAction = true, vm_sequence_id = mydata.machine.vm_sequence_id, type = 'reinstall'" class="px-5 py-2 w-full bg-warning text-white rounded">نصب مجدد سیستم عامل فعلی</vs-button>
            <vs-button @click="confirmAction = true, vm_sequence_id = mydata.machine.vm_sequence_id, type = 'move'" class="px-5 py-2 w-full bg-secondary text-white rounded my-4">انتقال ماشین</vs-button>
            <vs-button v-if="!mydata.machine.is_abuse" @click="confirmAction = true, vm_sequence_id = mydata.machine.vm_sequence_id, type = 'freeze'" class="px-5 py-2 w-full bg-danger text-white rounded">فریز کردن ماشین</vs-button>

            <vs-button v-if="mydata.machine.is_abuse" @click="unFeereze(mydata.machine.vm_sequence_id), type = 'freeze'" class="px-5 py-2 w-full bg-danger text-white rounded">آنفریز کردن ماشین</vs-button>

            <vs-button v-if="!mydata.machine.is_traffic_whitelist" @click="addToWhiteList(mydata.machine.vm_sequence_id, '1')" class="px-5 py-2 w-full bg-white text-primary mt-4 rounded">تغییر به 1 به 10</vs-button>

            <vs-button v-if="mydata.machine.is_traffic_whitelist" @click="addToWhiteList(mydata.machine.vm_sequence_id, '0')" class="px-5 py-2 w-full bg-black text-white border border-solid border-white border-opacity-50 mt-4 rounded">تغیر به 1 به 1</vs-button>

            <div v-if="confirmAction" class="text-left">
              <span class="text-3xl mb-3 text-white block text-center">لطفا درخواست خود را تایید کنید</span>
              <button v-if="(type === 'reboot')" @click="reboot(vm_sequence_id), confirmAction = false" class="px-5 py-2 w-full bg-success text-white rounded">ریبوت</button>
              <button v-if="(type === 'shutdown')" @click="shutdown(vm_sequence_id), confirmAction = false" class="px-5 py-2 w-full bg-warning text-white rounded">خاموش شود</button>
              <button v-if="(type === 'delete')" @click="deleteVm(vm_sequence_id), confirmAction = false" class="px-5 py-2 w-full bg-danger text-white rounded">حذف شود</button>
              <button v-if="(type === 'reinstall')" @click="reInstallVm(vm_sequence_id), confirmAction = false" class="px-5 py-2 w-full bg-danger text-white rounded">نصب مجدد سیستم عامل</button>
              {{  newVMNumber }}
              <vs-input v-if="(type === 'move')" class="inputx mb-2 w-full" placeholder="شناسه کاربر مقصد" v-model="userID"/>
              <vs-button v-if="(type === 'move')" @click="moveVM(vm_sequence_id, userID), confirmAction = false" class="px-5 py-2 w-full bg-secondary text-white rounded">انتقال</vs-button>

              <span v-if="(type === 'freeze')" class="text-white" >هر دلیلی خواستید بنویسید که یوزر موقع آپدیت، ریبوت حذف و ... مشاهده میکنه، اگر چیزی ننویسید پیام زیر رو مشاهده میکنه :
      این ماشین مجازی به دلیل دریافت Abuse از طرف دیتاسنتر مسدود میباشد</span>

              <vs-textarea v-if="(type === 'freeze')" class="inputx my-4 border border-white border-opacity-25 w-full" placeholder="دلیل فریز را درصورت نیاز اینجا بنویسید..." v-model="freezeText"/>
              <vs-button v-if="(type === 'freeze')" @click="freezeVM(vm_sequence_id, freezeText), confirmAction = false" class="px-5 py-2 w-full bg-secondary text-white rounded">فیریز</vs-button>
            </div>
        </div>


      </div>
    <!-- START OF TASKS -->
   <div class="flex flex-col">
  <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="py-4 inline-block min-w-full sm:px-6 lg:px-8">
      <div class="overflow-hidden">
        <div v-if="moreMessagesPopup"  >

          <ul v-if="task.error_msg != null"  :key="task, i" v-for="(task, i) in mydata.taskslist[taskindex].queue_info" class="bg-primaryLight rounded p-4 mb-4">
            <li>تلاش {{ i }} : {{ task.status_text}} </li>
            <vs-divider />
            <li>{{ task.error_msg }}</li>
          </ul>
        </div>
        <table class="min-w-full text-center">
          <thead class="text-xs text-grey text-center bg-primary">
            <tr class="border  border-white border-opacity-25 text-center">
              <th scope="col" class="border text-center border-white border-opacity-25 px-6 py-3">شناسه</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">نوع رویداد</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">وضعیت</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">جزئیات</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">زمان</th>
              <th scope="col" class="border border-white border-opacity-25 px-6 py-3">عملیات</th>
            </tr>
          </thead>
          <tbody class="">
            <tr class="border border-white border-opacity-25 text-center "  :key="task, index" v-for="(task, index) in mydata.taskslist" >

              <th scope="row" class="px-6 py-4 whitespace-nowrap text-center">{{ task.id }}</th>
              <td class="border border-white border-opacity-25 px-6 py-4 text-center">{{ task.method_name }}</td>
              <td class="border border-white border-opacity-25 px-6 py-4">{{ task.status_txt }}</td>
              <td v-if="!task.details.prev_cpu " class="border border-white border-opacity-25 px-2 py-4 text-center">
                <p v-if="task.queue_info[0]">                {{ task.queue_info[0].status_text }} - تعداد تلاش: {{ task.queue_info[0].retry_count }}
</p>
                <br/>
                <vs-button class="bg-grey-light text-black text-sm" type="grediant" @click="moreMessagesPopup = true, taskindex = index">اطلاعات بیشتر</vs-button>


              </td>
              <td v-if="task.details.prev_cpu" style="direction:ltr" class="border border-white border-opacity-25 px-2 py-4 text-center">
                <p v-if="task.queue_info[0]">
                {{ task.queue_info[0].status_text }} - CPU {{ task.details.prev_cpu }} to {{ task.details.new_cpu }} - RAM {{ task.details.prev_ram }} to {{ task.details.new_ram }} - Hard {{ task.details.prev_hdd }} to {{ task.details.new_hdd }}
                </p>
              </td>
              <td class="border border-white border-opacity-25 px-6 py-4 text-center">{{ task.created_at }}</td>
              <td class="border border-white border-opacity-25 px-6 py-4 text-center gap-4 flex justify-center">
                <span @click="reRunTask(task.id)" class="cursor-pointer">
                  <feather-icon  icon="RefreshCwIcon"  class="w-6 rotate" />
                </span>
                <span @click="cancelTask(task.id)" class="cursor-pointer">
                  <feather-icon icon="StopCircleIcon" class="w-6 rotate" />
                </span>

              </td>
            </tr>
          </tbody>
        </table>
      <vs-pagination class="mt-4 right-0 flex p-2" v-model="mydata.currenttasksPage" :total="mydata.taskslistParams.last_page"></vs-pagination>
  </div>
  </div>
  </div>
  </div>
  <!-- <vs-popup title="اطلاعات بیشتر از وضعیت تسک" :active.sync="moreMessagesPopup">
<div>
  dd
</div>
  </vs-popup> -->
    <!-- end of tasks -->


  </div>
</template>

<script>
export default {
    props: {
        mydata: {},
    },
  data() {
    return {
        confirmAction: false,
        type: '',
        currenttasksPage: this.mydata.currenttasksPage,
        fields: [
        { key: 'id', label: 'ID' },
        { key: 'method_name', label: 'Method Name' },
        { key: 'status', label: 'Status' },
        { key: 'details', label: 'Details' },
        { key: 'created_at', label: 'Created At' },
        { key: 'actions', label: 'Actions' }
      ],
      userID: '',
      newVMNumber: '',
      moreMessagesPopup:false,
      taskindex:''

    };
  },
  watch: {
    currenttasksPage () {
      this.getTasks(this.machineNumber.trim())
    }
  },
  methods: {

    reboot(id) {
      this.$http
        .get(`/admin/cloud/vm/${id}/reboot`)
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    shutdown(id) {
      this.$http
        .get(`/admin/cloud/vm/${id}/shutdown`)
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    deleteVm(id) {
      this.$http
        .post(`/admin/cloud/vm/${id}/delete`)
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    reInstallVm(id) {
      this.$http
        .post('/admin/cloud/vm/reinstall', { vm_sequence_id: id })
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
          this.newVMNumber = res.data.params.new_vm_no
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    moveVM(vmid, user) {
      this.$http
        .post('/admin/cloud/vm/move', { vm_sequence_id: vmid, new_user_id: user })
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    freezeVM(vmid, text) {
      this.$http
        .post('/admin/cloud/vm/abuse/set', { vm_id: vmid, abuse_reason: text })
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    unFeereze(vmid) {
      this.$http
        .post('/admin/cloud/vm/abuse/unset', { vm_id: vmid })
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    reRunTask(id) {
      this.$http
        .post("/admin/cloud/tasks/rerun",{task_id: id})
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    cancelTask(id) {
      this.$http
        .post("/admin/cloud/tasks/cancel",{task_id: id})
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
        })
        .catch((err) => {
          this.$vs.notify({ text: err.response.data.message });
        });
    },
    addToWhiteList(vm, action) {
      this.$http.post("/admin/cloud/vm/traffic/white/list", {vm_id: vm, action: action})
      .then((res) => {
        this.$vs.notify({ text: res.data.message})
      })
    }
  },


}
</script>
<style scoped>
 .bg-red {background-color: rgb(255, 72, 72); color: black; font-weight: 800;}
</style>
