<template>
  <div v-if="ticketData" class="md:flex gap-3">
    <vx-card class="px-4 w-full md:w-3/4">
      <vs-row>

        <div
          class="
            vx-col
            w-full
            border-b border-l-0 border-r-0 border-t-0
            d-theme-border-grey-light
            border-solid
            flex flex-wrap
            justify-between
            items-center
          "
        >
          <!-- Sender Details -->
          <div class="flex items-center">
            <div>
              <vs-avatar
                class="
                  sender__avatar--single
                  flex-shrink-0
                  mr-3
                  border-2 border-solid border-white
                "
                size="65px"
                @click="goToUser"
              />
            </div>

            <div class="flex flex-col">
              <h5 class="mb-1" v-if="ticketData[0].user_fullname">
                {{ ticketData[0].user_fullname }}
              </h5>
              <div class="flex items-center">
                <span class="text-sm"> Details </span>
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="inline-flex cursor-pointer"
                >
                  <feather-icon icon="ChevronDownIcon" class="w-3 h-3" />
                  <vs-dropdown-menu style="z-index: 40001">
                    <div class="p-2">
                      <p class="text-sm mb-1">
                        از:
                        <!-- <span class="font-semibold">
                          {{ ticketData[0].user_fullname }}
                          </span -->
                        >
                      </p>
                      <p class="text-sm mb-1">
                        شناسه کاربر :
                        <span class="font-semibold"> {{ userId }}</span>
                      </p>
                      <p class="text-sm mb-1">
                        به:
                        <span class="font-semibold">
                          {{ ticketData[0].category_name }}
                        </span>
                      </p>
                      <p class="text-sm mb-1">
                        تاریخ:
                        <span class="font-semibold">
                          {{ ticketData[0].updated_at }}
                        </span>
                      </p>
                      <p class="text-sm mb-1">
                        اولویت :<span class="font-semibold">
                          {{ ticketData[0].label_name }}
                        </span>
                      </p>
                    </div>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>
          </div>
          <!-- /Sender Details -->

          <!-- Mail Time & Date -->
          <div class="flex flex-col justify-end">
            <span class="text-right">{{ ticketData[0].created_at }} </span>
            <div class="flex justify-end mt-2">
              <span class="text-right">

                <a :href="'https://api.haio.ir/v1/download/ticket/etlX3ZdJ6NnoSJaXnqFcXuCjM/tid/' +  ticketData[0].id ">
                  <img :src="'https://api.haio.ir/v1/download/ticket/etlX3ZdJ6NnoSJaXnqFcXuCjM/tid/' +  ticketData[0].id" width="150" class="rounded"/>
                </a>
              </span>
            </div>
          </div>
        </div>
        <!-- /Mail Time & Date -->
      </vs-row>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mail__content mt-8 mb-4">
            <vs-alert class="h-full" :active="true" color="dark" icon="sms">
              {{ ticketData[0].body }}
            </vs-alert>
            <!-- <p>
                  {{ticketData[0].attachment}}
                </p> -->
          </div>
        </div>
        <!-- کامنت -->
      </div>
      <div>
        <ul
          id="chat"
          v-for="(item, index) in ticketData[0].comments"
          :key="index"
        >
          <li :class="item.comment_side ? 'me' : 'you'">
            <div class="entete">
              <span class="status green"></span>
              <h2 v-if="ticketData[0].user_fullname">
                {{ item.user_fullname }}
              </h2>
              <div
                @click="
                  $vs.notify({
                    title: 'درخواست شما با موفقیت با شکست مواجه شد',
                    text: 'هنوز متودی برای این دکمه در نظر گرفته نشده',
                    color: 'warning',
                  })
                "
                v-show="!item.comment_side"
                class="absolute"
              >
                <vs-icon class="my-1 bg-danger round" icon="close"></vs-icon>
              </div>
              <h3>{{ item.created_at }}</h3>
            </div>
            <div v-html="item.body" class="message"></div>
          </li>
        </ul>
        <span ref="end"></span>
      </div>

        <!-- :options="editorOption"  -->
      <quill-editor
        class="mb-4 h-full"
        v-model="message"
        :content="autoMessage"
      />
      <vs-button
        :disabled="!message"
        @click="
          sendReply();
          message = '';
        "
        >ارسال</vs-button
      >

          <vs-popup fullscreen :active.sync="Servicespopup">
           <!-- =========  VIRA CLOUD TABLE  ========================================================================================================================================================================================================================== -->
            <vx-card>
              <ViraCloud
                :userId="this.userId"
              ></ViraCloud>
            </vx-card>
          </vs-popup>

    </vx-card>

    <!-- SideBar -->
    <vx-card class="w-full md:w-1/4 mt-3 md:mt-0">
      <div class="md:fixed w-0">
        <!-- Change Ticket Status + Auto Respond -->
        <div class="my-4">
          <span class="block mb-2">تغییر وضعیت تیکت</span>
        <div class="flex gap-2 items-center">

          <vs-select class="" v-model="ticketStatus">
            <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.name"
            v-for="(item, index) in ticketStatusList"
            />
          </vs-select>
          <vs-button class="bg-success px-4 py-3" @click="setTicketStatus">تغییر</vs-button>
          </div>
        </div>
        <!-- End of Ticket Status -->
        <vs-divider />
        <!-- Auto Respons -->
        <div class="my-4">
          <span class="text- block mb-2">پاسخ آماده</span>
          <div class="flex gap-2 items-center ">
          <vs-select class="" v-model="autoMessageText">
            <vs-select-item
              :key="readyMessage"
              :value="readyMessage"
              :text="readyMessage"
              v-for="(readyMessage) in autoMessageList"
            />
          </vs-select>
          <vs-button class="bg-secondary px-4 py-3" @click="setMessage">پاسخ</vs-button>
          </div>
        </div>
        <!-- End of Auto Responds  -->
        <vs-divider />
        <!-- Show Machine Info -->
        <div class="">
          <form class="text-left mb-4">
            <span class="block mb-2">دریافت اطلاعات ماشین</span>
            <input class="block p-2 rounded border w-full bg-grey-light	text-primary" v-model="machineNumber" type="text" id="machineNumber">
            <input class="bg-secondary p-4 w-full rounded mt-2 text-base text-white" type="submit"
            @click.stop.prevent="machineInfoPopup = true, vmNumber = machineNumber, machineDetail(machineNumber), getTasks(machineNumber)" value=" اطلاعات ماشین">
          </form>
        </div>
        <!-- End of Machine Info -->
        <vs-divider />
        <div class="flex gap-2">

          <vs-button class="bg-grey text-primary" @click="Servicespopup = !Servicespopup">  سرویس‌ها</vs-button>
          <vs-button class="bg-grey text-primary" @click="TransListPopup = true , getAllTrans()"> ریز مصرف</vs-button>
        </div>
      </div>
    </vx-card>
    <!-- End of Sidebar -->
    <!-- Machine info Popup -->
    <vs-popup
      :fullscreen="true"
      title="اطلاعات ماشین"
      :active.sync="machineInfoPopup"
    >
    <machineInfoCard
    :mydata="{
        machine: this.machineInfo,
        machineNumber: this.machineNumber,
        machinInfoMessage: this.machinInfoMessage,
        taskslist: this.taskslist,
        taskslistParams: this.taskslistParams,
        currenttasksPage: this.currenttasksPage
    }"
    ></machineInfoCard>
    </vs-popup>
    <!-- End of Popup -->
    <!-- TransList Popup -->
    <vs-popup
      :fullscreen="true"
      title="گزارش مصرف کاربر"
      :active.sync="TransListPopup"
    >
    <TransList
    :radios1="this.radios1"
    :countPage="this.countPage"
    :pageNumber="this.pageNumber"
    :selectedUser="this.selectedUser"
    :userList="this.userList"
    :userId="this.userId"
    ></TransList>
    </vs-popup>
    <!-- End of Popup -->
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import User from "../views/UserPart/UserPage/User.vue";
import ViraCloud from "../views/UserPart/UserServices/serviceComponents/Viracloud.vue";

import UserServices from "../views/UserPart/UserServices/UserServices.vue";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import machineInfoCard from "./Dashboard/components/machinInfoCard.vue"
import TransList from "./components/TransList.vue"

export default {
  components: { VuePerfectScrollbar, quillEditor,User, UserServices, ViraCloud,machineInfoCard,TransList },
  props: ["id"],
  data() {
    return {
      // =====================QUILL EDITOR OPTIONS

      // =====================QUILL EDITOR OPTIONS
      // ------------------
      // ------------------
      // ------------------
      ticketStatus: "",
      ticketStatusList: [],
      userId: "",
      message: "",
      ticketID: "",
      ticketData: "",
      agent_name:'',
      autoMessage:'',
      autoMessageList: [
      'ساده',
      'ظرفیت',
      'عودت غیرقابل انجام',
      'عودت قابل انجام',
      'نمیتونم وصل بشم',
      'عدم دریافت پینگ و اتصال',
      'تراکنش ناموفق',
      'راه اندازی پروکسی',
      'سرعت سرورهای ایران',
      'ترافیک سرورهای ایران',
      'انتقال به اداری',
      ],
      autoMessageText:'',
      machineNumber: '',
      // trans
      userList:[],
      radios1: "all",
      countPage: 0,
      pageNumber: 1,
      selectedUser: [],

      //===POPUPS
      machineInfoPopup: false,
      moreutilitypopup: false,
      emailpopup: false,
      balancepopup: false,
      scorepopup: false,
      unsuspenduserpopup: false,
      TransListPopup: false,

      //---
      //---
      //---
      //--SERVICES
      Servicespopup: false,
      ServicesHaiocloudpopup: false,
      ServicesHostpopup: false,
      ServicesViracloudpopup: false,
      ServicesVPCpopup: false,
      //===POPUPS

      currenttasksPage:1,
      taskslistParams: [],
      taskslist:[],
      alltasksPage: 1,
      allTotal: 0,
      alltaskslistParams: [],
      alltaskslist:[],
      machineInfo:[],
      machinInfoMessage:'',
    };
  },
  watch: {
    "$route.params.id"(value) {
      console.log(value.params.id);
      this.ticketID = this.$route.params.id;
    },
    pageNumber() {
      this.getAllTrans();
    },
    radios1() {
      this.getAllTrans();
    },
  },
  methods: {
    getAllTicketStatus() {
      this.$http
        .get("admin/ticket/status/list?name")
        .then((res) => {
          this.ticketStatusList = res.data.params.data.data;
          // console.log(this.ticketStatus)
        })
        .catch();
    },
    goto() {
      var element = this.$refs["end"];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    goToUser() {
      this.$router.push(`/user?id=${this.userId}`);
    },
    getTicketdetails() {
      this.$http
        .get(`admin/ticket/${this.ticketID}`)
        .then((res) => {
          this.ticketData = res.data.params.data;
          this.userId = this.ticketData[0].user_id;
          this.ticketStatus = this.ticketData[0].status_id;
          console.log(this.ticketData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setTicketStatus() {
      this.$http
        .put(`admin/ticket/${this.ticketData[0].id}`, {
          status_id: this.ticketStatus,
        })
        .then((res) => {
          this.$vs.notify({ text: res.data.message });
          if (this.ticketStatus === 5) {
            this.autoMessage =
             "سلام و درود، <br /> تیکت شما درحال بررسی می باشد، به زودی پاسخ مربوطه از طریق همین تیکت به شما ارسال خواهد. <br /> <br /> ---------- <br />  از شکیبایی و همکاری شما سپاسگزاریم. (پیام سیستم)";
          }
          if (this.ticketStatus === 2) {
            this.autoMessage =
             "سلام و درود، <br /> تیکت شما دریافت شد و به زودی یکی از کارشناسان پشتیبانی از طریق همین تیکت با شما ارتباط میگرد. <br /> <br /> ---------- <br />  از شکیبایی و همکاری شما سپاسگزاریم. (پیام سیستم)";
          }
          if (this.ticketStatus === 6) {
            this.autoMessage =
             "سلام و درود، <br /> درخواست شما درحال اجرا می باشد، به زودی نتیجه درخواست از طریق همین تیکت به شما ارسال خواهد. <br /> <br /> ---------- <br />  از شکیبایی و همکاری شما سپاسگزاریم. (پیام سیستم)";
          }
        })
        .catch();
    },
    assignToMe() {
      this.$http
        .get(`/user/info`)
        .then((res) => {
          this.agent_name = res.data.params.name;

        })
        .catch();
    },
    sendReply() {
      this.$vs.loading();
      this.$http
        .post(`/admin/ticket/comment/${this.ticketID}`, {
          body_html: this.message,
        })
        .then((res) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: res.data.message,
          });
          console.log(this.ticketData);
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        })
        .finally(() => {
          this.message = "";
          this.getTicketdetails();
        });
    },
    setMessage(){
      switch (this.autoMessageText) {
        case 'ساده':
          this.autoMessage = 'سلام و درود، <br /><br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم. '
        break
        case 'ظرفیت':
          this.autoMessage = 'سلام و درود، <br /> موجودی سرور ها بصورت لحظه ای درحال تغییر می باشد و  دقیق مشخص نیست کی ظرفیت خالی ایجاد میشود، لذا پیشنهاد میشود مرتب از طریق پنل ساخت سرور را بررسی کنید تا بلافاصله بعد از آزاد شدن اولین آیپی بتوانید اقدام به ساخت کنید.<br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.';
          break
        case 'عودت غیرقابل انجام':
          this.autoMessage = 'سلام و درود، <br /> با توجه به قوانین هایو عودت وجه برای درخواست شما مقدور نمی باشد. <br /> haio.ir/terms <br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.';
          break
        case 'عودت قابل انجام':
          this.autoMessage = 'سلام و درود، <br /> <br />جهت ثبت درخواست عودت وجه لطفاً مدارک زیر را در تیکت جدیدی ارسال نمایید. <br /><br />تصویر کارت ملی صاحب پنل کاربری <br />شماره کارت و شبا به نام صاحب پنل کاربری <br /> <br />دقت داشته باشید در راستای قانون مبارزه با پولشویی می بایست شماره کارت واریزی با شماره کارتی که درخواست عودت برای آن ثبت می شود یکی باشد. <br />ضمناً در صورتی که افزایش شارژ پنل با شماره کارت دیگری (به جز شماره کارت صاحب پنل) انجام شده باشد، ارسال مدارک صاحب شماره کارت نیز علاوه بر مدارک فوق الزامیست. <br /> <br/>-----------<br />از شکیبایی و همکاری شما سپاسگزاریم.';
          break
        case 'عدم دریافت پینگ و اتصال':
          this.autoMessage = 'سلام و درود، <br /> احتمال دارد این مشکل از سمت ISP شما به وجود آمده باشد، لطفا با یک اینترنت دیگر تست کنید. <br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.';
          break
        case 'تراکنش ناموفق':
          this.autoMessage = 'سلام و درود، <br />لطفا اطلاعات دقیق تراکنش را جهت بررسی بیشتر به شرح زیر ارسال کنید <br /> تاریخ و ساعت دقیق تراکنش، شماره کارت صاحب حساب، مبلغ تراکنش <br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.';
          break
        case 'نمیتونم وصل بشم':
          this.autoMessage = 'سلام و درود، <br />لطفا ابتدا اطمینان حاصل کنید که از پورت 2280 برای اتصال به سرور استفاده میکنید، اگر مشکل با پورت 2280 همچنان باقی بود و نمیتوانستید وصل بشید پینگ سرور رو بررسی کنید ببنید پینگ دارید یا خیر و بعد وضعیت رو به ما خبر بدید؛ <br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.';
          break
        case 'راه اندازی پروکسی':
          this.autoMessage = 'سلام و درود، <br /> هایو صرفا ارائه دهنده سرور ابری است، تنظیمات و برنامه هایی که شما در سرور قصد راه اندازی دارید کاملا بر عهده خودتان است و محدودیتی وجود ندارد، اما دقت داشته باشید طبق قوانین کشور هیچگونه پشتیبانی فنی برای راه اندازی انواع پروکسی و تعهدی بابت محدودیت های زیرساخت ارائه نمی شود. <br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.'
          break
        case 'سرعت سرورهای ایران':
          this.autoMessage = 'سلام و درود، <br /> سرعت پیشفرض سرورهای ایران 1 گیگابیت اشتراکی است اما درصورتی که ترافیک 1 به 1 داشته باشید (اقدام به راه اندازی پروکسی کرده باشید) و مصرف شما با نسبت 1 به 1 شما از حد تعریف شده بیشتر شود سرعت سرور شما به 5 مگابیت دانلود و 15 مگابیت آپلود محدود می شود <br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.'
          break
        case 'ترافیک سرورهای ایران':
          this.autoMessage = 'سلام و درود، <br /> ترافیک سرورهای ایران نامحدود می باشد، و فقط در صروتیکه نسبت مصرف شما در سرورهای ایران 1 به 1 باشد هزینه ترافیک دریافت میشود در غیر اینصورت هزینه ای نیز برای ترافیک وجود نخواهد داشت و میتوانید نامحدود استفاده ببرید. <br /> هزینه ترافیک 1 به 1 به ازای هر گیگ دانلود 500 تک تومان می باشد. <br />مد نظر داشته باشید درصورت استفاده از نرم افزار های ایجاد کننده ترافیک فیک مثل نامیزون این مورد شناسایی می شود و مجاز نخواهد بود <br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.'
          break
        case 'انتقال به اداری':
          this.autoMessage = 'سلام و درود، <br /> تیکت شما به بخش مربوطه ارجاع داده شد و در اولین فرصت در ساعات اداری پاسخ لازم ارائه خواهد شد. <br /> <br /> ----------- <br /> از شکیبایی و همکاری شما سپاسگزاریم.'
          break
      }
    },
    // machineDetail(id) {
    //   this.$http
    //       .get(`/admin/cloud/vm/${id}`)
    //       .then((res) => {
    //         this.machineInfo =  res.data.params;
    //         })
    //       .catch((err) => {
    //       console.log(err);
    //     });
    // },
    getTasks(vm_id) {
      const mahcine_vm_n = vm_id
      this.$http.get(`/admin/cloud/tasks?filter=&page=&user_id=&vm_id=${mahcine_vm_n}&node_id=&status=&page=${this.currenttasksPage}`)
        .then(response => {
          this.taskslistParams = response.data.params
          this.taskslist = response.data.params.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    getAllTasks() {
      this.$http.get(`/admin/cloud/tasks?filter=&page=${this.alltasksPage}&user_id=&vm_id=&node_id=&status=`)
        .then(response => {
          this.alltaskslistParams = response.data.params
          this.allTotal = response.data.params.last_page
          this.alltaskslist = response.data.params.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    machineDetail(id) {

      this.$http
          .get(`/admin/cloud/vm/${id.trim()}`)
          .then((res) => {
            this.machineInfo =  res.data.params;
            return this.machinInfoMessage = ''

          })
          .catch((err) => {
            console.log(err);
            if (err) {
              return this.machinInfoMessage = '404'
            }

        });

    },
    getAllTrans() {
      this.$vs.loading();
      this.$http
        .get(
          `/admin/billing/pay/transaction?type=${this.radios1}&user_id=${this.userId}&page=${this.pageNumber}`
        )
        .then((res) => {
          this.$vs.loading.close();
          const p = res.data.params;
          this.countPage = p.last_page;
          this.userList = p.data;
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log(err);
        });
    },

  },
  created() {
    this.ticketID = this.$route.params.id;
    console.log(this.ticketID);
    this.getTicketdetails();
    this.getAllTicketStatus();
    this.userId;
  },
  mounted() {
    setTimeout(() => {
      this.goto();
    }, 1000);
  },
};
</script>
<style lang="css" scoped>
* {
  box-sizing: border-box;
}
body {
  background-color: #abd9e9;
  font-family: Arial;
}
#container {
  width: 750px;
  height: 800px;
  background: #eff3f7;
  margin: 0 auto;
  font-size: 0;
  border-radius: 5px;
  overflow: hidden;
}
aside {
  width: 260px;
  height: 800px;
  background-color: #3b3e49;
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
}
main {
  width: 490px;
  height: 800px;
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
}

aside header {
  padding: 30px 20px;
}
aside input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 50px 0 20px;
  background-color: #5e616a;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/ico_search.png);
  background-repeat: no-repeat;
  background-position: 170px;
  background-size: 40px;
}

#chat {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}
#chat li {
  padding: 10px 30px;
}
#chat h2,
#chat h3 {
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
}
#chat h3 {
  color: #bbb;
}
#chat .entete {
  margin-bottom: 5px;
}
#chat .message {
  padding: 20px;
  color: #fff;
  line-height: 25px;
  max-width: 90%;
  display: inline-block;
  text-align: left;
  border-radius: 5px;
}
#chat .me {
  text-align: right;
}
#chat .you .message {
  background-color: #58b666;
}
#chat .me .message {
  background-color: #6fbced;
}
#chat .triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
}
#chat .you .triangle {
  border-color: transparent transparent #58b666 transparent;
  margin-left: 15px;
}
#chat .me .triangle {
  border-color: transparent transparent #6fbced transparent;
  margin-left: 375px;
}
</style>
